import styled from "styled-components";

export const Paper = styled.div`
  padding: 44px 36px 43px;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  margin-bottom: 99px;
  position: relative;
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 50px 58px;
  }
  @media (max-width: 767px) {
    padding: 32px 16px;
    margin-bottom: 0;
  }
`;
